<template>
  <div v-if="timeUp">
    <h1>Ok</h1>
  </div>
  <div v-else>
    <h1>Off</h1>
  </div>
</template>
<script>
import { renderPage } from "@/firebase";

export default {
  name: "Test",
  data() {
    return {
      timeUp: null,
      timer : ''
    };
  },
  mounted(){
        this.timer = setInterval(this.doSmthg, 15000);
  },
  methods: {
    doSmthg(){
      console.log('test');
    }
  },
};
</script>
<style scoped>
button {
  width: 100%;
  margin: 1em 0;
}
</style>
